import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-3 col-lg-3 col-md-6 col-sm-6" }
const _hoisted_3 = { class: "col-xxl-3 col-lg-3 col-md-6 col-sm-6" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body pt-0" }
const _hoisted_6 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_7 = {
  key: 1,
  class: "badge badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-8",
          "svg-icon": "/media/icons/duotune/finance/fin006.svg",
          color: "info",
          "icon-color": "white",
          title: _ctx.$n(_ctx.$store.state.AuthModule.user.al_wallet, 'currency'),
          description: _ctx.$t('DashboardSummary.CurrentAl')
        }, null, 8, ["title", "description"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-8",
          "svg-icon": "/media/icons/duotune/finance/fin001.svg",
          color: "success",
          "icon-color": "white",
          title: _ctx.$n(_ctx.totalDeposit, 'currency'),
          description: _ctx.$t('Deposit.TotalDeposit')
        }, null, 8, ["title", "description"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Datatable, {
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true
        }, {
          "cell-memo": _withCtx(({ row: deposit }) => [
            (deposit.memo === 'ADD')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("Deposit." + deposit.memo)), 1))
              : (deposit.memo === 'DEDUCT')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("Deposit." + deposit.memo)), 1))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["table-data", "table-header"])
      ])
    ])
  ], 64))
}