
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface IAlDeposit {
  createdAt: string;
  amount: string;
  memo: string;
}

export default defineComponent({
  name: "al-deposit-history",
  components: {
    StatisticsWidget5,
    Datatable,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const route = useRoute();
    // page variables
    const tableHeader = ref([
      {
        name: t("Deposit.Date"),
        key: "createdAt",
        text: true,
        sortable: true,
      },
      {
        name: t("Deposit.Type"),
        key: "memo",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Deposit.Amount"),
        key: "amount",
        currency: true,
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IAlDeposit>>([]);
    const totalDeposit = ref<number>(0);

    const getAlDeposits = async (type: string) => {
      const results = await ApiService.get(`/al/deposits`)
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      if (results.length > 0)
        totalDeposit.value = results
          .map((item) => Number(item.amount))
          .reduce((prev, next) => Number(prev) + Number(next));
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.AlAddHistory"), [t("Menu.AlManage")]);
      getAlDeposits(String(route.params.type).toUpperCase());
    });
    return {
      tableData,
      tableHeader,
      totalDeposit,
    };
  },
});
